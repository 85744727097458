<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>
<script>
import * as echarts from 'echarts'
import { debounce } from '@/utils'
import { markRaw } from 'vue'
export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    title:{
      type: String,
      default: '温度波动对比'
    },
    colors: {
      type: Array,
      default: ()=>{
        return ['#3ABB83','#F6F853','#F6BF5E', '#F65559']
      }
    },
    yAxis:{
      type:Array,
      default:()=>{
          return  []
      }
    },
    wdminList:{
      type:Array,
      default:()=>{
          return []
      }
    },
    wdmaxList:{
      type:Array,
      default:()=>{
          return []
      }
    },
  },

  data() {
    return {
      chart:null
    }
  },
  watch:{
    'yAxis':{
      handler() {
         this.refreshData()
      },
      deep:true,
    },
  },
  mounted() {
    this.refreshData()
    this.__resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize()
      }
    }, 500)
    window.addEventListener('resize', this.__resizeHandler)
  },
  beforeUnmount() {
    if (!this.chart) {
      return
    }
    window.removeEventListener('resize', this.__resizeHandler)
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
        this.chart = markRaw(echarts.init(this.$el))
        this.setOption()
    },
    setOption(){
        let _this = this
        this.chart.setOption({
          tooltip: {
              trigger: 'axis',
              axisPointer: {
                  type: 'shadow'
              },
              formatter: function (params) {
                  var tar0 = params[0];
                  var tar1 = params[1];
                  return tar0.name + '<br/>' + tar0.seriesName + ' : ' + tar0.value + '<br/>' +  tar1.seriesName + ' : ' + (Number(tar1.value) + Number(tar0.value))
              }
          },
          grid: {
              left: '2%',
              right: '4%',
              bottom: '0%',
              top:'10%',
              containLabel: true
          },
          color:this.colors,
          yAxis: {
              type: 'value',
              splitLine: { show: false },
              axisTick: {
                  show: false,
              },
              data:this.yAxis,
              axisLabel: {
                  show: true,
    
                  color: "#fff",
                  fontSize: 12,
              },
          },
          xAxis: {
              type: 'category',
              splitLine: { show: false },
              axisLine: { show: true },
              axisTick: {
                  show: true,
                  inside:true,
              },
              axisLabel: {
                  show: true,
                  interval: 0,//使x轴文字显示全
                  color: "#fff",
                  fontSize: 12,
                  formatter: function(params) {
                    var newParamsName = "";
                    var paramsNameNumber = params.length;
                    var provideNumber = 2; //一行显示几个字
                    var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                    if (paramsNameNumber > provideNumber) {
                      for (var p = 0; p < rowNumber; p++) {
                        var tempStr = "";
                        var start = p * provideNumber;
                        var end = start + provideNumber;
                        if (p == rowNumber - 1) {
                          tempStr = params.substring(start, paramsNameNumber);
                        } else {
                          tempStr = params.substring(start, end) + "\n";
                        }
                        newParamsName += tempStr;
                      }
                    } else {
                      newParamsName = params;
                    }
                    return newParamsName;
                  }
              },
              interval: 0,//使x轴文字显示全
              data:this.yAxis
          },
          series: [
              {
                  name: '最小值',
                  type: 'bar',
                  stack: 'Total',
                  barWidth:15,
                  itemStyle: {
                      borderColor: 'transparent',
                      color: 'transparent'
                  },
                  emphasis: {
                      itemStyle: {
                      borderColor: 'transparent',
                      color: 'transparent'
                      }
                  },
                  data: this.wdminList,
                  label:{
                      show:true,
                      position: 'insideTop',
                      color: '#fff',
                      fontSize: 12,
                  }
              },
              {
                  name: '最大值',
                  type: 'bar',
                  barWidth:15,
                  stack: 'Total',
                  data: this.wdmaxList.map((e,index)=>{
                      return e - this.wdminList[index]
                  }),
                  label:{
                      show:true,
                      position: 'top',
                      color: '#fff',
                      fontSize: 12,
                      formatter:(params)=>{
                          return this.wdmaxList[params.dataIndex]
                      }
                  },
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                      offset: 0,
                      color: '#FF9B0B'
                    },
                    {
                      offset: 1,
                      color: '#B5E23D'
                    }
                    ]),
                  },
                  emphasis: {
                    itemStyle: {
                      opacity: 1,
                    }
                  },
              },
          ]
        })
    },
    refreshData(){
        //刷新数据
        if(this.chart){
          this.setOption();  
        }else{
          this.initChart()
        }  
    }
  }
}

</script>